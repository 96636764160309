<template>
    <div>
        <b-row>
            <b-col cols="4" style="border: 2px solid var(--primary)">

                <b-row class="header" align-v="center">
                    <b-col>Ladedaten exportieren</b-col>
                </b-row>

                <b-row class="p-4">
                    <label for="select-export-type">Export Typ:</label>
                    <b-form-select id="select-export-type" v-model="exportType">
                        <option v-for="option in getExportTypes()" :key="option.value" :value="option.value">
                            {{ option.text }}
                        </option>
                    </b-form-select>
                </b-row>

                <b-row class="text-left">
                    <b-col class="p-4">
                        <label for="start-date">Startdatum:</label>
                        <b-form-datepicker id="start-date" v-model="startDate" class="mb-2"></b-form-datepicker>

                        <label class="mt-4" for="end-date">Enddatum:</label>
                        <b-form-datepicker id="end-date-date" v-model="endDate" class="mb-2"></b-form-datepicker>

                        <b-row class="mt-4">
                            <b-col class="text-right">
                                <b-btn class="align-self-end" @click="exportExcel">Export</b-btn>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import {getSummaryTypeStrings, SummaryTypesExport} from "@/types/enums";

export default {
    name: "DataExport",
    methods: {
        getExportTypes() {
            return [
                {value: '', text: 'Bitte wählen'},
                {value: 'default', text: 'Ladedaten'},
                {value: SummaryTypesExport.UNIPER_ABC, text: 'Bericht UNIPER ABC'},
                {value: SummaryTypesExport.UNIPER_CONSUMPTION_HYDRO, text: 'Bericht UNIPER Verbrauch Hydrokraftwerke'},
                // {value: SummaryTypesExport.CAR_POOL_MANAGER, text: 'Bericht Fuhrparkmanager'}, // TODO maybe implement these later, for now they are not used
                // {value: SummaryTypesExport.PAYMENT, text: 'Bericht Lohnabteilung'},
                // {value: SummaryTypesExport.EMPLOYEE, text: 'Bericht Mitarbeiter'}, // TODO open select for employee
                // {value: SummaryTypesExport.AREA_MANAGER, text: 'Bericht L3-Manager'}, // TODO open select for area
            ]
        },
        exportExcel() {
            let url = ''
            let responseType = 'blob'
            let fileName = ''
            if (this.exportType === 'default') {
                url = `excelExport/${this.startDate}/${this.endDate}`
            } else if (Object.values(SummaryTypesExport).includes(this.exportType)) {
                url = `dataSummary/${this.startDate}/${this.endDate}/${this.exportType}`
                responseType = 'arraybuffer'
            } else {
                return
            }
            this.$api.get(url, {
                responseType: responseType
            }).then((response) => {
                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');
                const exportTypeString = getSummaryTypeStrings(this.exportType)
                const startDateString = this.startDate.split('-').reverse().join('-')
                const endDateString = this.endDate.split('-').reverse().join('-')

                if (response.headers['content-disposition']) {
                    fileName = response.headers['content-disposition'].split('"')[1]
                } else {
                    fileName = `Bericht e-mobility (${exportTypeString}) ${startDateString} - ${endDateString}.xlsx`
                }

                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);

                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    },
    data() {
        return {
            startDate: '',
            endDate: '',
            exportType: '',
        }
    }
}
</script>

<style>

.header {
    color: white;
    background-color: var(--primary);
    font-size: larger;
    height: 50px;
}

</style>
